import React, {
  createContext,
  useReducer,
  useEffect,
  PropsWithChildren,
} from "react";

type State = {
  open: boolean;
};

type Action = {
  type: "toggle";
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "toggle": {
      return {
        ...state,
        open: !state.open,
      };
    }
    default: {
      return state;
    }
  }
};

export type ModalProvoderState = State & {
  dispatch: React.Dispatch<Action>;
};

export const ModalContext = createContext<ModalProvoderState>({
  open: false,
  dispatch: () => {
    throw new Error("ModalContext is not yet initialized");
  },
});

export const ModalContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(reducer, {
    open: false,
  });
  useEffect(() => {
    if (state.open) {
      document.body.classList.remove("Modal__Close");
      document.body.classList.add("Modal__Open");
    } else {
      document.body.classList.remove("Modal__Open");
      document.body.classList.add("Modal__Close");
    }
  }, [state.open]);
  return (
    <ModalContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
