import React, {
  useState,
  useMemo,
  createContext,
  PropsWithChildren,
} from "react";

export type Language = "ja" | "en";

const defaultLanguage = "ja";

export type LanguageProviderState = {
  language: Language;
  changeLanguage: (language: Language) => void;
};

export const LanguageContext = createContext<LanguageProviderState>({
  language: defaultLanguage,
  changeLanguage: () => {
    throw new Error("LanguageContext not yet initialized.");
  },
});

export const LanguageContextProvider = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  const [language, setLanguage] = useState<Language>(defaultLanguage);

  const value: LanguageProviderState = useMemo(
    () => ({
      language,
      changeLanguage: (next: Language) => {
        setLanguage(next);
      },
    }),
    [language, setLanguage],
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
