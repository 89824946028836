import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { withContext } from "~context";
import * as styles from "../index.module.scss";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";

const Component: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      thumbnail: file(relativePath: { eq: "kenro/event_210818.jpg" }) {
        publicURL
      }
      takashi: file(relativePath: { eq: "kenro/takashi.jpg" }) {
        publicURL
      }
    }
  `);

  const CONTACT_FORM_ID =
    "32Qon6HPX35lEwj7Y49BpsyoOxxADw76PbAdIEVHc2nAaDFJizADfjxoIzA3QfDc7";

  return (
    <Kenro2PaneLayout
      meta={{
        title:
          "30分で理解できる、セキュアなWeb開発を「いい感じ」に始める方法（Python編）",
        image: `https://flatt.tech${data.thumbnail.publicURL}`,
        description:
          "プロダクトの抱えるセキュリティリスクを最小限にし、安定したプロダクト提供を実現していくためには、一人ひとりの開発者が高いセキュリティ意識を持っていることが重要です.しかし、そもそもセキュリティについて常に意識し続けながら開発を進めるためには慣れが必要ですし、どうしてもミスをしてしまうこともあります。そもそも、セキュリティに関する知識を全員が高く持てているチームはそう多くありません。",
      }}
      optionalFormID={CONTACT_FORM_ID}
    >
      <div className={styles.KenroEvent}>
        <div className="Voice_TitleWrapper">
          <h5 className={styles.KenroEvent_SubTitle}>SECURE CODING Dojo #2</h5>
          <h1 className={styles.KenroEvent_Title__SteelBlue}>
            30分で理解できる、セキュアなWeb開発を「いい感じ」に始める方法（Python編）
          </h1>
          <img
            className={styles.KenroEvent_MainImage}
            src={data.thumbnail.publicURL}
            alt=""
          />
        </div>
        <div className="Voice_ContentWrapper">
          <h2>イベント概要</h2>
          <p>
            プロダクトの抱えるセキュリティリスクを最小限にし、安定したプロダクト提供を実現していくためには、一人ひとりの開発者が高いセキュリティ意識を持っていることが重要です。
            しかし、そもそもセキュリティについて常に意識し続けながら開発を進めるためには慣れが必要ですし、どうしてもミスをしてしまうこともあります。そもそも、セキュリティに関する知識を全員が高く持てているチームはそう多くありません。{" "}
          </p>
          <p>
            <b>
              そこで本ウェビナーでは、そんな不安を軽減しつつセキュアな Web
              サービス開発を進めていくための “備え” として、以下を紹介します。
            </b>
          </p>
          <ul>
            <li>シフトレフト・DevSecOpsの考え方</li>
            <li>
              自社 Web
              アプリケーションをセキュアにしていくためのベストプラクティス（&#x203B;
              とりわけ今回は Python 製の Web
              アプリケーションを題材として、具体的なツール・SaaSや環境設定例も紹介します）
            </li>
          </ul>
          <p>
            もっと安心してプロダクトを前に進められるチーム・環境を作っていくための足がかりを、ぜひ本ウェビナーを通して得ていただければと思います。
          </p>
          <p>
            &#x203B;本セミナーでは、7月13日に開催した「明日から出来る DevSecOps
            - Ruby on Rails
            編」の内容と似通った部分がございます。ご参加いただく方はご注意くださいませ。
          </p>
          <h3>開催日時</h3>
          <p>2021/08/18(水) 14:15~15:00</p>
          <h3>視聴方法</h3>
          <p>
            Zoom
            ウェビナー（フォームよりお申し込み後、URLをメールでお送りいたします）
          </p>
          <h3>タイムライン</h3>
          <ul>
            <li>14:15 ~ 14:20：イントロダクション</li>
            <li>
              14:20 ~ 14:40：セキュアな Web サービス開発のための “備え” とは -
              Python 編
            </li>
            <li>14:40 ~ 14:45：KENROサービス紹介</li>
            <li>14:45 ~ 14:55：質疑応答</li>
            <li>14:55 ~ 15:00：アンケート回収＆チェックアウト</li>
          </ul>
          <h3>こんな方におすすめ</h3>
          <ul>
            <li>セキュリティを学びたいWeb アプリケーションエンジニア</li>
            <li>Web サービスのセキュリティ業務を兼務しているエンジニア</li>
          </ul>
          <h3>定員</h3>
          <p>100名</p>
          <h3>登壇者プロフィール</h3>
          <img src={data?.takashi?.publicURL || ""} />
          <b>米内貴志 / 株式会社Flatt Security / CTO 最高技術責任者</b>
          <p>
            {`2019年に株式会社 Flatt Security にセキュリティエンジニアとして入社。Web エンジニアのセキュアコーディング習得を支援するクラウド型学習プラットフォーム「KENRO」のシステム開発・コンテンツ監修に従事。2021年6月より CTO に就任。
              
              「セキュリティ・キャンプ」事業での講師や、電気通信大学ウェブシステムデザインプログラム講師、SECCON CTF 実行委員会メンバー等を歴任。著書に『Web ブラウザセキュリティ - Web アプリケーションの安全性を支える仕組みを整理する』（ラムダノート株式会社、2021 年）や『詳解セキュリティコンテスト』（株式会社マイナビ出版、2021 年）がある。`}
          </p>

          <h3>備考</h3>
          <p>
            {`※本セミナーは オンライン（ウェビナー）での実施となります。
              ※法人様対象のセミナーのため、個人でのお申し込みは受け付けておりません。
              ※主催企業と事業が競合する企業からのお申し込みは、お断りする場合がございます。
              ※本セミナーの録画録音などはお断りさせていただいております。`}
          </p>
        </div>
      </div>
    </Kenro2PaneLayout>
  );
};

export default withContext(Component);
